import "./App.css"
import React from "react"
import Hero from "./components/hero"
import Home from "./components/home"
import Footer from "./components/footer"
import Login from "./components/loginPage"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import ProtectedRoutes from "./components/protectedRoutes"
import Signup from "./components/signUp"
import ForgotPassword from "./components/forgotPassword"


function App() {
  return (
    <React.Fragment>
      <Router>
        <Routes>
        <Route
              path="/"
              element={
                <React.Fragment>
                  <Home />
                  <Footer />
                </React.Fragment>
              }
            />

          <Route element={<ProtectedRoutes />}>
            <Route
              path="/upload"
              element={
                <React.Fragment>
                  <Hero />
                  <Footer />
                </React.Fragment>
              }
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          
        </Routes>
      </Router>
    </React.Fragment>
  )
}

export default App
