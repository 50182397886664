import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs, doc } from "firebase/firestore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const presetsDoc = collection(db, "presets");

export default function DropDown(props) {
  const { selesctedPreset, setSelectedPreset } = props;
  const [presets, setPresets] = useState([]);
  useEffect(() => {
    getDocs(presetsDoc).then((snaps) => {
      snaps.forEach((doc) => {
        const newDoc = doc.data();
        const array = Object.keys(newDoc);

        setPresets(array.sort());
      });
    });
  }, []);

  return (
    <div className="relative inline-block text-white hover:text-[#92C5EB]">
      <select
        id="countries"
        value={selesctedPreset}
        onChange={(e) => {
          setSelectedPreset(e.target.value);
        }}
        className=" cursor-pointer min-w-[15rem] block appearance-none bg-[#12202D] border-none outline-none py-3 px-4 pr-8 rounded-lg"
      >
        <option selected>Select Preset</option>
        {presets.map((p) => {
          return (
            <option key={p} value={p} className="text-white">
              {p}
            </option>
          );
        })}
      </select>
    </div>
  );
}
