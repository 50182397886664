import React, { useEffect, useState, useRef } from "react";
import cloud from "../image/voiceProcessor-logo.png";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);
  const captchaRef = useRef(null);

  const onRecaptchaChange = (value) => {
    if (value) setValidated(!!value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.currentUser) navigate("/");
  }, [navigate]);

  const onChangeHandler = (e) => {
    setUser((prevUser) => {
      return { ...prevUser, [e.target.name]: e.target.value };
    });
  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (!validated) {
      alert("Please complete the reCaptcha first!");
      return;
    }
    signInWithEmailAndPassword(auth, user.email, user.password)
      .then((userCredential) => {
        localStorage.setItem("userCredential", JSON.stringify(userCredential));
        navigate("/upload");
      })
      .catch((error) => {
        console.log("error", error);
      });
    captchaRef.current.reset();
  };
  return (
    <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0">
      <div className="bg-white sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg">
        <div className="my-12 max-w-[12rem] mx-auto">
          <img src={cloud} alt="cloud image" />
        </div>
        <h3 className="text-center font-medium text-2xl	">Login</h3>
        <div className="px-4 py-8 sm:px-10">
          <div className="mt-6">
            <form action="#" onSubmit={submitHandler}>
              <div className="mb-2">
                <label htmlFor="mobile-or-email" className="sr-only">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  onChange={onChangeHandler}
                  value={user.email}
                  id="mobile-or-email"
                  autoComplete="email"
                  placeholder="Email"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={user.password}
                  onChange={onChangeHandler}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="mt-1 flex justify-between">
                <div>
                  <a
                    className="text-blue-500 hover:underline underline-offset-1"
                    href="/forgot-password"
                  >
                    Forgot Password
                  </a>
                </div>
                <div>
                  Not a member yet?{" "}
                  <a
                    className="text-blue-500 hover:underline underline-offset-1"
                    href="/signup"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
              <div className="py-2">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={"6Ldkj3EkAAAAAPYdhU4e2OOm2kjR1Kg_ChmA_h16"}
                  onChange={onRecaptchaChange}
                />
              </div>
              <button
                type="submit"
                className="flex my-6 w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
