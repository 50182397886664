import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";

import CloudVoiceImage from "../image/voiceProcessor-logo.png";

import DropDown from "./dropdown";
import FileUpload from "./fileUploadInput";
import { useNavigate } from "react-router-dom";
import {
  auth,
  fetchToken,
  db,
  onMessageListener,
} from "../firebase/firebaseConfig";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import axios from "axios";
import { toast } from "react-toastify";

export default function Hero() {
  const [selesctedPreset, setSelectedPreset] = React.useState();
  const [isOpen, setIsOpen] = useState(false);
  const [fileDownloadUrl, setFileDownLoadUrl] = useState(null);
  const [isTokenFound, setTokenFound] = useState(false);
  const currentUser = auth.currentUser;
  const searchParams = new URLSearchParams(document.location.search);

  fetchToken(setTokenFound);

  const navigate = useNavigate();
  console.log("navigatenavigate", searchParams);
  const handleNotification = () => {
    console.log("Clicked");
    const docRef = doc(db, "users", currentUser.uid);
    getDoc(docRef).then((doc) => {
      const user = doc.data();

      if (user) {
        console.log("here");
        axios.post("http://localhost:4000/notificationAdmin", {
          token: isTokenFound,
        });
      }
    });
  };

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      const { title, body } = payload.data;
      toast.info(`${title}; ${body}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    })
    .catch((err) => {
      toast.error(JSON.stringify(err), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });

  const logoutHandler = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("userCredential");
        navigate("/login");
      })
      .catch((error) => {
        console.log("logout failed ===> ", error);
      });
  };
  return (
    <div className="relative overflow-hidden bg-gradient-to-r from-[#0D1218] to-[#141B25] text-white">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    File is uploaded
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 break-words">
                      {fileDownloadUrl}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* <div
        className="hidden sm:absolute sm:inset-0 sm:block"
        aria-hidden="true"
      >
        <svg
          className="absolute bottom-0 right-0 mb-48 translate-x-1/2 transform text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:translate-x-0 xl:transform-none"
          width={364}
          height={384}
          viewBox="0 0 364 384"
          fill="none"
        >
          <defs>
            <pattern
              id="eab71dd9-9d7a-47bd-8044-256344ee00d0"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width={364}
            height={384}
            fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)"
          />
        </svg>
      </div> */}
      <div className="relative pt-6 pb-16 sm:pb-24">
        <Popover>
          <nav
            className="relative mx-auto max-w-7xl px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center justify-between">
              <div className="flex w-full items-center justify-between md:w-auto">
                <a href="#">
                  <span className="sr-only">Your Company</span>
                  <img className="h-20 w-auto" src={CloudVoiceImage} alt="" />
                </a>
              </div>
              <div className="">
                <a
                  onClick={logoutHandler}
                  className="whitespace-nowrap inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white hover:bg-gray-700"
                >
                  Sign Out
                </a>
              </div>
            </div>
          </nav>
        </Popover>

        <main className="mt-16 sm:mt-24">
          <div className="max-w-3xl mx-auto text-center text-lg px-2">
            <h1 className="mt-4 text-4xl font-bold tracking-tight sm:text-5xl md:text-7xl">
              Upload Your <div className="text-[#92C5EB]">mp3/wav</div>
            </h1>
            <p className="mt-3 sm:font-semibold sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Upload your mp3/wav file into the upload file area and select the
              desired output preset and hit the upload button. You’ll get an
              email notification when your file is ready to download.
            </p>
            <p className="mt-4 text-base font-medium sm:font-bold text-white">
              NOTE: *Minimum filesize is 100kb *Maximum upload size is 100Mb
            </p>
            <div className="mt-8 max-w-xl flex flex-col items-center sm:flex-row sm:items-start justify-center flex-wrap gap-5 sm:mx-auto text-center">
              <DropDown
                selesctedPreset={selesctedPreset}
                setSelectedPreset={setSelectedPreset}
              />
              <FileUpload
                setIsOpen={setIsOpen}
                setFileDownLoadUrl={setFileDownLoadUrl}
                selesctedPreset={selesctedPreset}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
