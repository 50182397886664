import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import cloud from "../image/voiceProcessor-logo.png";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import ReCAPTCHA from "react-google-recaptcha";

export default function Signup() {
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (auth?.currentUser) navigate("/");
  }, [navigate]);
  const onRecaptchaChange = (value) => {
    if (value) setValidated(!!value);
  };
  const onChangeHandler = (e) => {
    setUser((prevUser) => {
      return { ...prevUser, [e.target.name]: e.target.value };
    });
  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (!validated) {
      alert("Please complete the reCaptcha first!");
      return;
    }
    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then((userCredential) => {
        setDoc(doc(db, "users", userCredential.user.uid), {
          email_address: userCredential.user.email || user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          Admin: false,
          status: false,
          processedFile: "null",
        });
        navigate("/login");
      })
      .catch((error) => {
        if (error.code === "auth/email-already-in-use") {
          alert('Please Use Another Email ID", "This email ID already exists.');
        }
      });
  };
  return (
    <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0">
      <div className="bg-white sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg">
        <div className="my-12 max-w-[12rem] mx-auto">
          <img src={cloud} alt="cloud image" />
        </div>
        <h3 className="text-center font-medium text-2xl	">Sign Up</h3>
        <div className="px-4 py-8 sm:px-10">
          <div className="mt-6">
            <form onSubmit={submitHandler}>
              <div className="mb-2">
                <label htmlFor="mobile-or-fName" className="sr-only">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  onChange={onChangeHandler}
                  value={user.firstName}
                  id="mobile-or-fName"
                  autoComplete="firstName"
                  placeholder="First Name"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="mobile-or-lName" className="sr-only">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  onChange={onChangeHandler}
                  value={user.lastName}
                  id="mobile-or-lName"
                  autoComplete="lastName"
                  placeholder="Last Name"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div className="mb-2">
                <label htmlFor="mobile-or-email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={onChangeHandler}
                  value={user.email}
                  id="mobile-or-email"
                  autoComplete="email"
                  placeholder="Mobile number or email"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={user.password}
                  onChange={onChangeHandler}
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="mt-1 flex justify-between">
                <div></div>
                <div>
                  Already a member ?{" "}
                  <a
                    className="text-blue-500 hover:underline underline-offset-1"
                    href="/login"
                  >
                    Login
                  </a>
                </div>
              </div>
              <div className="py-2">
                <ReCAPTCHA
                  sitekey={"6Ldkj3EkAAAAAPYdhU4e2OOm2kjR1Kg_ChmA_h16"}
                  onChange={onRecaptchaChange}
                />
              </div>
              <button
                type="submit"
                className="flex my-6 w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Sign Up
              </button>
            </form>
          </div>
        </div>
        <div className="border-t-2 border-gray-200 bg-gray-50 px-4 py-6 sm:px-10">
          <p className="text-xs leading-5 text-gray-500">
            By signing up, you agree to our{" "}
            <a href="#" className="font-medium text-gray-900 hover:underline">
              Terms
            </a>
            ,{" "}
            <a href="#" className="font-medium text-gray-900 hover:underline">
              Data Policy
            </a>{" "}
            and{" "}
            <a href="#" className="font-medium text-gray-900 hover:underline">
              Cookies Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
