import React, { Fragment, useEffect, useRef, useState } from "react";
import { storage } from "../firebase/firebaseConfig";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { db, auth } from "../firebase/firebaseConfig";
import {
  addDoc,
  collection,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function FileUploadInput(props) {
  const { selesctedPreset, fileDownloadUrl, setFileDownLoadUrl, setIsOpen } =
    props;
  const currentUser = auth.currentUser;
  const [progresspercent, setProgresspercent] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleSubmit = () => {
    if (!currentUser.uid) return;

    const file = fileInputRef.current.files[0];

    if (!file) return alert("Attach the file then upload");
    if (!selesctedPreset) return alert("Please select the preset");

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },

      async () => {
        const docRef = await doc(db, "users", currentUser.uid);

        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFileDownLoadUrl(downloadURL);
          setIsOpen(true);
          addDoc(collection(db, "users"), {
            ...userData,
            originalFile: downloadURL,
            presetName: selesctedPreset,
            preset: selesctedPreset,
            status: false,
            preset: selesctedPreset,
            userId: currentUser.uid,
            processedFile: null,
            email_address: currentUser.email,
          }).then((data) => {
            console.log("Successfully added Data to Firestore", data);
            //  console.log(downloadURL)
            //    setDoc(doc(db, "users", currentUser.uid), {
            //     ...userData,
            //     preset: selesctedPreset,
            //     status: false,
            //     originalFile: downloadURL
            //   });
            // }).then(async(data) => {
            const functions = getFunctions();
            try {
              const sendEmail = httpsCallable(functions, "sendEmail");

              console.log("userData", userData, docSnap.data());
              sendEmail({
                userId: currentUser.uid,
                downloadURL: downloadURL,
                name: userData.firstName + " " + userData.lastName,
              }).then((result) => {
                // Read result of the Cloud Function.
                /** @type {any} */
                console.log(result);
              });
            } catch (error) {
              console.log(error);
            }
          });
        });
      }
    );
  };

  return (
    <>
      <form className="cursor-pointer">
        <div>
          <div className="mx-auto text-base flex justify-center rounded-lg bg-[#12202D] items-center px-4 py-3 w-64">
            <label
              htmlFor="file-upload"
              className="flex gap-5 cursor-pointer hover:text-[#92C5EB]"
            >
              <span className="break-all">
                {uploadedFile ? uploadedFile : "Select a File"}
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                strokeWidth={1.5}
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                />
              </svg>
            </label>

            <input
              ref={fileInputRef}
              id="file-upload"
              name="file-upload"
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file.size > 100 * 1024 * 1024)
                  return alert("The size of the file is too large");

                if (file.type !== "audio/mpeg" && file.type !== "audio/wav")
                  return alert("Only MP3 and Wav audio files can be uploaded");
                setUploadedFile(file.name);
              }}
              className="sr-only "
            />
          </div>
        </div>
      </form>
      <button
        onClick={handleSubmit}
        type="submit"
        className="mx-auto px-7 py-2 border border-transparent text-base capitalize rounded-2xl shadow-sm text-gray-900 font-bold bg-[#92C5EB] hover:bg-indigo-200 focus:outline-none transition duration-300"
      >
        {progresspercent === 0 || progresspercent === 100
          ? "upload"
          : `${progresspercent}%`}
      </button>
    </>
  );
}
