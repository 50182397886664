import { Outlet, Navigate } from 'react-router-dom';
import { auth } from '../firebase/firebaseConfig';

import React from 'react';

const ProtectedRoutes = () => {
	return auth?.currentUser && auth.currentUser.uid ? (
		<Outlet />
	) : (
		<Navigate to='/login' />
	);
};

export default ProtectedRoutes;
