import React, { useEffect, useState } from "react";
import cloud from "../image/voiceProcessor-logo.png";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebaseConfig";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";

export default function ForgotPassword() {
  const [user, setUser] = useState({
    email: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.currentUser) navigate("/");
  }, [navigate]);

  const onChangeHandler = (e) => {
    setUser((prevUser) => {
      return { ...prevUser, [e.target.name]: e.target.value };
    });
  };
  const submitHandler = (event) => {
    event.preventDefault();

    sendPasswordResetEmail(auth, user.email, {
      url: "https://cloudvoiceprocessor.firebaseapp.com/login#",
      handleCodeInApp: true,
    })
      .then((userCredential) => {
        alert("Email with login link sent to your email address.");
      })
      .catch((error) => {
        console.log("error", error.code, JSON.stringify(error));
        if (error) {
          alert(error.code);
        }
      });
  };
  return (
    <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0">
      <div className="bg-white sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg">
        <div className="my-12 max-w-[12rem] mx-auto">
          <img src={cloud} alt="cloud image" />
        </div>
        <h3 className="text-center font-medium text-2xl	">Login</h3>
        <div className="px-4 py-8 sm:px-10">
          <div className="mt-6">
            <form action="#" onSubmit={submitHandler}>
              <div className="mb-2">
                <label htmlFor="mobile-or-email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={onChangeHandler}
                  value={user.email}
                  id="mobile-or-email"
                  autoComplete="email"
                  placeholder="Email"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <button
                type="submit"
                className="flex my-6 w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
